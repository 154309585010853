<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveBranch)" id="user-form">
                    <h5>Datos generales</h5>
                    <b-row class="mb-2">
                        <b-col cols="12" md="12" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Nombre" invalid-feedback="Ingresa el nombre">
                                    <b-form-input v-model="branch.name" autocomplete="false" placeholder="Nombre"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'BranchIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button variant="secondary" :to="{ name: 'BranchShow', params: { id: branch.id }}" v-if="branch.id" class="mr-2">
                        <font-awesome-icon icon="search"></font-awesome-icon> Detalle
                    </b-button>
                    <b-button type="submit" form="user-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Guardar</b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { extend } from 'vee-validate';
import { required, integer } from 'vee-validate/dist/rules';
import { branchUrl } from '@routes';
import * as constants from '@constants';

extend('required', {
    ...required,
    message: 'Campo requerido'
});

extend('integer', {
    ...integer,
    message: 'Campo requerido'
});

export default {
    data() {
        return {
            branch: {
                id: 0,
                name: ''
            },
            swalTitle: 'Sucursales',
            breadcrumb: {
                title: 'Sucursales',
                path: [
                    {
                        name: 'BranchIndex',
                        text: 'Sucursales'
                    }
                ]
            },
            action: 'Nueva',
            signature: null,
            oldSignature: null,
            isLoading: false,
            file: null
        };
    },
    created() {
        if (this.$route.name.includes('Edit')) {
            this.loadData();

            this.action = 'Modificar';
        }

        this.breadcrumb.path.push({
            name: this.$route.name,
            text: `${this.action}`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(branchUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    let branch = response.data.branch;

                    this.branch.id = branch.id;
                    this.branch.name = branch.name;
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        saveBranch() {
            this.isLoading = true;

            this.axios.post(branchUrl(), this.branch).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'success'
                    });

                    this.$router.push({ name: 'BranchShow', params: { id: response.data.id }});
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                console.error(error);

                this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    computed: {
    },
    watch: {
    }
};
</script>

<style scoped>
</style>